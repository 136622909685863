//Theme colour variable overrides for various bits

:root,
[data-theme="light"] {

//Brand colours 

//Brand Purple
--ms-brand-primary:       								#9A3EFF;
--ms-brand-primary-active:    						    #8a32e8;
--ms-brand-primary-darkmode:       						#AD61FF;
--ms-brand-primary-active-darkmode:    					#b570ff;
--ms-brand-primary-light:    						    #f8f5ff;
--ms-brand-primary-dark:  								#331555;
--ms-brand-primary-inverse:  							#fff;    

//Brand Pink
--ms-brand-secondary:       							#FEC8FA;
--ms-brand-secondary-rgb:                               254, 200, 250;
--ms-brand-secondary-active:    						#e9b1e6;
--ms-brand-secondary-light:    							#fee4fc;
--ms-brand-secondary-dark:								#423241;
--ms-brand-secondary-inverse:  							#fff;


//Button colours
--kt-info-light: var(--ms-brand-primary-light);

//Nav link colours
--kt-menu-link-color-active: var(--ms-brand-primary-active);
--kt-menu-link-color-hover: var(--ms-brand-primary);

//Form colours
--kt-form-check-input-checked-bg-color-solid:  var(--ms-brand-primary)
}


[data-theme="dark"] {

//Button colours
--kt-info-light: var(--ms-brand-primary-dark);

//Nav link colours
--kt-menu-link-color-active: var(--ms-brand-primary-active-darkmode);
--kt-menu-link-color-hover: var(--ms-brand-primary-darkmode);
--kt-text-info: var(--ms-brand-primary-darkmode);

//Form colours
--kt-form-check-input-checked-bg-color-solid:  var(--ms-brand-primary-darkmode);
}
