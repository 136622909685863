// Text overrides for custom Mindshare theme

.text-brand, .text-hover-brand:hover {
    color: var(--ms-brand-primary) !important;
}
.text-brand, .text-hover-brand:hover {
    color: var(--ms-brand-primary) !important;
}

[data-theme="dark"] {
    .text-brand, [data-theme="dark"] .text-hover-brand:hover {
        --ms-brand-primary: var(--ms-brand-primary-darkmode) !important;
    }
}