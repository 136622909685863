
//Some extra styling to tweak various components

//Card sticky footer background
.card {
    .card-footer.sticky {
        background-color: var(--kt-card-bg)
    }
}
.btn.btn-light-info {
    color: var(--kt-info-active);
}

//Date range picker
.daterangepicker .drp-calendar td.active,
.tagify .tagify__tag .tagify__tag__removeBtn:hover {
    background-color: var(--ms-brand-primary)!important;
}
.daterangepicker .drp-calendar td.today, .daterangepicker .drp-calendar td.today.active,
.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today),
.daterangepicker .drp-calendar th.available:hover, .daterangepicker .drp-calendar td.available:not(.end-date):not(.start-date):hover,
.btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon,
.tagify__dropdown .tagify__dropdown__item:hover,
.accordion-button:not(.collapsed) {
    color: var(--ms-brand-primary)!important;
}

[data-theme="dark"] {
    .btn-light-info,
    .badge-light-info,
    .btn.btn-active-light-info:hover:not(.btn-active),
    .btn.btn-outline.btn-outline-info,
    .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon {
        color: var(--ms-brand-primary-active-darkmode);
    }
    //CK Editor dark mode background adjust
    .ck.ck-editor__main>.ck-editor__editable {
        background-color: var(--kt-input-solid-bg);
    }
}

// Brand pink color for background and text use

.bg-brand-pink {
    --kt-bg-rgb-color: var(--ms-brand-secondary-rgb);
    background-color: var(--ms-brand-secondary);
}

// Toast plugin overrides
#toastr-container > div {opacity: 1}

// Acordian overrides

.accordion {
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%239A3EFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

//modal z-index override so tagify isn't behind them modal
.modal_tagify_dropdown,
.tagify__dropdown {
    z-index: 999999!important;
}


//Tagify single select override width
.tagify--select .tagify__input {
    width:auto!important
}


.timeline-label {
    &::before {left: 93px}
    .timeline-label {
        width: 92px;
    }
}


// Winner button (in COTM Deliberations tab)
.winner[disabled] {opacity: 1}

// Making date range picker z index bigger
.daterangepicker {
    z-index: 2000;
}

//Menu dropdown colours to add purple info variant

.menu-state-bg-light-info .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), 
.menu-state-bg-light-info .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here),
.menu-state-bg-light-info .menu-item .menu-link.active {
    transition: color 0.2s ease;
    background-color: var(--kt-info-light);
    color: var(--kt-info);
}

.loading-screen{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}