.object-fit {
    &-cover {object-fit: cover} 
    &-contain {object-fit: contain}
    &-top-left {object-position: top left}
    &-right-bottom {object-position: right bottom}
    &-left-bottom {object-position: left bottom}
}
.img-hover-grow {
    .img-grow {transition: transform .8s ease}
    &:hover .img-grow {
        transform: scale(1.2)
    }
}
.cover-link {
    &::before {
        content:"";
        position: absolute;
        inset: 0
    }
}
.mindshare-gradient {
    background: linear-gradient(45deg, var(--ms-brand-primary) 10%, rgba(44,12,74,0) 60%);
}
.sticky {
    position: sticky;
}
.card-footer{
    z-index: 9;
}