.header, .header-fixed[data-kt-sticky-header=on] .header {
    background-color: var(--ms-brand-primary);
}
.btn-check:checked + body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom,
.btn-check:active + body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom,
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:focus:not(.btn-active),
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:hover:not(.btn-active),
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:active:not(.btn-active),
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.active, body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.show,
.show > body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
@include media-breakpoint-up(lg) {
    body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item.show > .menu-link,
    body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
    body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item:not(.here) > .menu-link:hover:not(.disabled):not(.active):not(.here),
    body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item.here > .menu-link {
        background-color: rgba(0, 0, 0, 0.1);
    }
    body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item > .menu-link .menu-arrow:after {
        background-color: #fff;
    }
}

.menu-state-primary .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
.menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here),
.menu-state-primary .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-title,
.menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title,
.menu-state-primary .menu-item .menu-link.active,
.menu-state-primary .menu-item .menu-link.active .menu-title {
    color: var(--ms-brand-primary)
}

[data-theme="dark"] {
    .menu-state-primary .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
    .menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here),
    .menu-state-primary .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-title,
    .menu-state-primary .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title,
    .menu-state-primary .menu-item .menu-link.active,
    .menu-state-primary .menu-item .menu-link.active .menu-title {
    --ms-brand-primary: var(--ms-brand-primary-darkmode)
}
}