// Customisations to plugins for Mindshare.  All variables for colour point to variables in the 'components/_root.custom.scss' file. 

//Select 2 dropdown colour changes

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected,
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--highlighted {
    color: var(--kt-menu-link-color-active)
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected:after {
    background-color: var(--kt-menu-link-color-active)
}